import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'app-connectwallet',
  templateUrl: './connectwallet.component.html',
  styleUrls: ['./connectwallet.component.css']
})
export class ConnectwalletComponent implements OnInit {
  modalOpened: boolean = false;

  constructor(public dialogRef: MatDialogRef<ConnectwalletComponent>,private cs:GlobalService) { }

  ngOnInit(): void {
    this.getDetails();
  }

   getDetails()
  {
     this.cs.getData().subscribe((data:any)=>{
       debugger
      if(data.address!=undefined && this.modalOpened){
        this.closeDialog();
      }
      
    });
  }

  closeDialog() {
    this.dialogRef.close('Pizza!');
  }

  connect(walletType:any)
  {
    this.modalOpened=true;
    if(walletType==1){
    this.cs.connectToWallet();
    }
    else
    {
      this.cs.connectToWalletConnect();
    }
  }
}
