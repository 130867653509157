<app-header></app-header>

<section
  class="apps-craft-welcome-section section-padding"
  style="height: fit-content"
>
  <div class="container" style="max-width: 1440px">
    <div class="row">
      <div class="col-md-8">
       
          <div class="tab-content">
            <div id="menu2" class="tab-pane active">
              
                <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3" >
                  <div class="token-balances-container mb-2">
                    <div class="balance-title-container">
                      <div class="small-message-font h6 amount">
                        {{ globalInfo?._total_active_invested / 1e18+(egcGlobalRewards/1e18)+(zeroAddressBalance/1e18) | number:'0.0-0'}}
                      </div>
                      <div class="text-primary balance-font-0 h3 mt-0">
                        Elevators Stake 
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
                  <div class="token-balances-container mb-2">
                    <div class="balance-title-container">
                      <div class="small-message-font h6 amount">
                        {{ globalInfoNW?._total_active_invested / 1e18  | number:'0.0-0'}}
                      </div>
                      <div class="text-primary balance-font-0 h3 mt-0">
                        Holders Stake
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
                  <div class="token-balances-container mb-2">
                    <div class="balance-title-container">
                      <div class="small-message-font h6 amount">
                        {{ (globalInfo?._total_active_invested*1/1e18+globalInfoNW?._total_active_invested*1 / 1e18)+(egcGlobalRewards/1e18)+(zeroAddressBalance/1e18)  | number:'0.0-0' }}
                      </div>
                      <div class="text-primary balance-font-0 h3 mt-0">
                        Total Stake
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
                  <div class="token-balances-container mb-2">
                    <div class="balance-title-container">
                      <div class="small-message-font h6 amount">
                        {{ globalInfoNW?._users*1+globalInfo?._users*1 }}
                      </div>
                      <div class="text-primary balance-font-0 h3 mt-0">
                        Number of Users
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
                  <div class="token-balances-container mb-2">
                    <div class="balance-title-container">
                      <div class="small-message-font h6 amount">
                        <!-- {{ globalInfo?._users }} -->
                        51
                      </div>
                      <div class="text-primary balance-font-0 h3 mt-0 text-center">
                        Total Elevators
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
                  <div class="token-balances-container mb-2">
                    <div class="balance-title-container">
                      <div class="small-message-font h6 amount">
                        {{ (globalInfo?._withdrawn/1e18+globalInfoNW?._withdrawn / 1e18)  | number:'0.0-0' }}
                      </div>
                      <div class="text-primary balance-font-0 h3 mt-0 text-center">
                        Paid Rewards
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
                  <div class="token-balances-container mb-2">
                    <div class="balance-title-container">
                      <div class="small-message-font h6 amount">
                        271 Million
                      </div>
                      <div class="text-primary balance-font-0 h3 mt-0 text-center">
                        Max EGON Supply
                      </div>
                    </div>
                  </div>
                </div>
               
            </div>
          </div>
       
       
          <div class="selector">
           
            <div class="selecotr-item">
                <input type="radio" (change)="setFlagWhitelisted(false)" id="radio2" name="selector" class="selector-item_radio" checked>
                <label for="radio2" class="selector-item_label">Holders</label>
            </div>
            <div class="selecotr-item">
              <input type="radio" (change)="setFlagWhitelisted(true)" id="radio1" name="selector" class="selector-item_radio" >
              <label for="radio1" class="selector-item_label">Elevators</label>
          </div>
        </div>
       
      
        <div class="col-sm-12">
          <p class="mt-3 text-black bnbBalance">
            My Balance : {{userbalance/1e18 | number:'0.0-0'}}
          </p>
          
        </div>
       


          <div class="col-lg-12 p-0 d-xs-none" style="padding: 0;">
            <div class="Deposite_section ">
              <div class="table-responsive">
                <table class="table custom_table">
                  <thead style="border-bottom: solid 2px #eef3f6">
                    <th class="content">Amount</th>
                    <th class="content">Duration</th>
                    <th class="content">Deposite time</th>
                    <th class="content">Withdrawn</th>
                    <th class="content" style="text-align: end">Action</th>
                  </thead>
                  <tbody *ngIf="whitelistOn">
                    <tr
                      *ngFor="let item of userInfoROI?.deposits; let i = index"
                    >
                      <td>{{ item.amount / 1e18  | number: "1.4-4"}}
                      </td>
                      <td>
                        {{ this.packageDetails[item.package]?.invest_days }} days
                      </td>
                      <td>
                        {{
                          item?.time*1000 | date: "dd-MM-yyyy HH:mm"
                        }}
                      </td>
                      <td>{{ item.withdrawn / 1e18  | number: "1.4-4"}}</td>
                      <td style="text-align: end">
                        <span *ngIf="item.isUnstaked"> Unstaked </span>
                        <button
                          *ngIf="!item.isUnstaked"
                          class="apps-craft-btn solid-color custom-btn"
                          (click)="unstake(i)"
                        >
                          Unstake
                        </button>
                      </td>
                    </tr>
                  </tbody>

                  <tbody *ngIf="!whitelistOn">
                    <tr
                      *ngFor="let item of userInfoROINW?.deposits; let i = index"
                    >
                      <td>{{ item.amount / 1e18 }}
                      </td>
                      <td>
                        {{ this.packageDetailsNW[item.package]?.invest_days }} days
                      </td>
                      <td>
                        {{
                          item?.time*1000 | date: "dd-MM-yyyy HH:mm"
                        }}
                        
                      </td>
                      <td>{{ item.withdrawn / 1e18 }}</td>
                      <td style="text-align: end">
                        <span *ngIf="item.isUnstaked"> Unstaked </span>
                        <button
                          *ngIf="!item.isUnstaked"
                          class="apps-craft-btn solid-color custom-btn"
                          (click)="unstake(i)"
                        >
                          Unstake
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
       
      </div>
      
      <div class="col-md-4">
        <div class="box-green bg-secondary rounded p-3 mb-3">
          <div class="text-input">
            <h2 class="selecttext">Select Stake Duration </h2>
            <div class="divide">
              

              <div class="row">
                <div class="col-lg-12">
                  <div class="Earning">
                    <div class="Profit_per">
                      <div class="Earn col-sm-12" *ngIf="whitelistOn">
                        <span
                          *ngFor="let item of packageDetails; let i = index"
                          (click)="setPackage(i)"
                          
                          
                        >
                        <p [ngClass]="{ active: package == i }" *ngIf="item?.invest_days>0">
                          {{ item.invest_days }} days
                        </p>
                        </span>
                      </div>

                      <div class="Earn col-sm-12" *ngIf="!whitelistOn">
                        <span
                          *ngFor="let item of packageDetailsNW; let i = index"
                          (click)="setPackage(i)"
                          
                          
                        >
                        <p [ngClass]="{ active: package == i }" *ngIf="item?.invest_days>0">
                          {{ item.invest_days }} days for
                          {{ (item.dailypercentage / 1000)*item.invest_days  | number:'0.0-0'}}%
                        </p>
                        </span>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>

              <input
                type="text"
                #amount1
                name="amount1"
                placeholder="0.00"
                class="form-control Amount"
              />
             
              <div class="col-lg-12 col-xs-12" style="margin-top:10px;    position: relative;">
                <div class="mb-2" *ngIf="whitelistOn">
                  <div class="text-black"> Min stake amount : 50,000</div>
                </div>

                <div class="mb-2" *ngIf="!whitelistOn">
                  <div class="text-black"> Min stake amount : 10</div>
                  <div class="text-black"> Max stake amount : 127,100</div>
                </div>


                <button
                  class="filled-btn" 
                  (click)="invest(amount1.value)" 
                >
                Stake My EGON!
                </button>
              </div>
            </div>
            
          </div>
        </div>
        <div class="box-green bg-secondary rounded p-3 mb-3 mt-5">
          <div class="row">
            <div class="col-lg-12">
            
                <div class="withdraw">
                  <table class="table borderless">
                    <thead class="borderless"></thead>
                    <tbody *ngIf="whitelistOn">
                      <tr>
                        <td scope="col" class="borderless">Token Staked :</td>
                        <td scope="col " class="text-end borderless">
                          {{ userInfoROI?.invested / 1e18 | number: "1.4-4" }}
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">Amount Earned :</td>
                        <td class="text-end">
                          {{
                            userInfoROI?.revenue_for_withdraw / 1e18
                              | number: "1.8-8"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">Amount claimed :</td>
                        <td class="text-end">
                          {{ userInfoROI?.withdrawn / 1e18 | number: "1.8-8" }}
                        </td>
                      </tr>
                    </tbody>

                    <tbody *ngIf="!whitelistOn">
                      <tr>
                        <td scope="col" class="borderless">Token Staked :</td>
                        <td scope="col " class="text-end borderless">
                          {{ userInfoROINW?.invested / 1e18 | number: "1.4-4" }}
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">Amount Earned :</td>
                        <td class="text-end">
                          {{
                            userInfoROINW?.revenue_for_withdraw / 1e18
                              | number: "1.8-8"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td scope="row">Amount claimed :</td>
                        <td class="text-end">
                          {{ userInfoROINW?.withdrawn / 1e18 | number: "1.8-8" }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- <h2 style="visibility: hidden;">Select Stake Package</h2> -->
                  <!-- <p>Token Staked : {{userInfoROI?.invested/1e18 | number:'1.4-4'}}</p>
              <p>Amount Earned : {{userInfoROI?.revenue_for_withdraw/1e18  | number:'1.8-8'}}</p>
              <p>Amount claimed : {{userInfoROI?.withdrawn/1e18   | number:'1.8-8'}}</p> -->
                  <div class="mb-2">
                  <div class="text-danger"> Premature Unstake  : 15% fee from reward earned</div>
                  </div>

                  <div class="mb-2">
                    <button
                  class="filled-btn" 
                  (click)="withdraw()" 
                >
                Withdraw
                </button>
                  </div>
                </div>
            
            </div>
          </div>
        </div>
      </div>


      <div class="col-lg-12 d-lg-none">
        <div class="Deposite_section ">
          <div class="table-responsive">
            <table class="table custom_table">
              <thead style="border-bottom: solid 2px #eef3f6">
                <th class="content">Amount</th>
                <th class="content">Duration</th>
                <th class="content">Deposite time</th>
                <th class="content">Withdrawn</th>
                <th class="content" style="text-align: end">Action</th>
              </thead>
              <tbody *ngIf="whitelistOn">
                <tr
                  *ngFor="let item of userInfoROI?.deposits; let i = index"
                >
                  <td>{{ item.amount / 1e18  | number: "1.4-4"}}
                  </td>
                  <td>
                    {{ this.packageDetails[item.package]?.invest_days }} days
                  </td>
                  <td>
                    {{
                      item?.time*1000 | date: "dd-MM-yyyy HH:mm"
                    }}
                  </td>
                  <td>{{ item.withdrawn / 1e18  | number: "1.4-4"}}</td>
                  <td style="text-align: end">
                    <span *ngIf="item.isUnstaked"> Unstaked </span>
                    <button
                      *ngIf="!item.isUnstaked"
                      class="apps-craft-btn solid-color custom-btn"
                      (click)="unstake(i)"
                    >
                      Unstake
                    </button>
                  </td>
                </tr>
              </tbody>

              <tbody *ngIf="!whitelistOn">
                <tr
                  *ngFor="let item of userInfoROINW?.deposits; let i = index"
                >
                  <td>{{ item.amount / 1e18 }}
                  </td>
                  <td>
                    {{ this.packageDetailsNW[item.package]?.invest_days }} days
                  </td>
                  <td>
                    {{
                      item?.time*1000 | date: "dd-MM-yyyy HH:mm"
                    }}
                  </td>
                  <td>{{ item.withdrawn / 1e18 }}</td>
                  <td style="text-align: end">
                    <span *ngIf="item.isUnstaked"> Unstaked </span>
                    <button
                      *ngIf="!item.isUnstaked"
                      class="apps-craft-btn solid-color custom-btn"
                      (click)="unstake(i)"
                    >
                      Unstake
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>
