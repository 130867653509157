<div class="row connect-wallet-text">
    <div class="col-md-12 p-0">
      <div class="sc-uxdHp btkIRC">
        <div class="sc-jSFjdj sc-gKAaRy sc-biJonm kJmatq jcNvwq ernndx">
          <h2 color="text" class="sc-gtsrHT sc-kLojOw jDnmwq jDXLmf">
            Withdrawal Txn
          </h2>
        </div>
       
      </div>
    </div>
  </div>
  <div class="inner-box">
    <div class="row m-0">
      <div class="apps-craft-single-feature">
        <div>
            <p>
                {{btnText}}
            </p>
            <button (click)="closeDialog()" class="btn btn-primary"> OK </button>
        </div>
      </div>
      
    </div>
  </div>
  