import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ethers } from 'ethers';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-txn-pop-up',
  templateUrl: './txn-pop-up.component.html',
  styleUrls: ['./txn-pop-up.component.css']
})
export class TxnPopUpComponent implements OnInit {
  modalOpened: boolean = false;
  btnText: string="";

  constructor(public dialogRef: MatDialogRef<TxnPopUpComponent>,private cs:GlobalService,@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.getDetails();
  }

   async getDetails()
  {
    try {
        this.btnText="Transaction Initiated...";
        var status: any;
        if(this.data.whitelistOn){
          status = await this.cs.exchangeContract.deposit(
            this.data.package,{value:ethers.utils.parseEther(this.data.amount)}
          );
        }
        else{
          status = await this.cs.exchangeAddressNonWhitelistContract.deposit(
            this.data.package,{value:ethers.utils.parseEther(this.data.amount)}
          );
        }
          this.btnText = "Waiting for Confirmation...";
          await status.wait(3);
          this.btnText = "Transaction Completed Successfully....!";
    }catch (e) {
      this.btnText = "Something went wrong";
      
      // if (e.code == 4001){
      //    this.toastr.error(e.message);
      // }
      // else{
      //    this.toastr.error(e.data.message);
      // }
    }
  }

  closeDialog() {
    this.dialogRef.close('Pizza!');
  }

}