import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../services/global.service';
import { ActivatedRoute } from '@angular/router';
import { ethers } from 'ethers';
import { TxnPopUpComponent } from './txn-pop-up/txn-pop-up.component';
import { MatDialog } from '@angular/material/dialog';
import { UnstakePopupComponent } from './unstake-popup/unstake-popup.component';
import { WithdrawPopupComponent } from './withdraw-popup/withdraw-popup.component';


@Component({
  selector: 'app-swap',
  templateUrl: './swap.component.html',
  styleUrls: ['./swap.component.css'],
})
export class SwapComponent implements OnInit {
  nooftoken: number = 0;
  contractaddress: string | undefined;
  account: string = '';
  tronWeb: any;
  playerinfo: any;
  isConnected: any;
  tokenBalance: any = 0;
  userbalance: any = 0;
  data: any;
  elevatorsBalance: any;
  holdersBalance: any;
  priceInfo: any;
  sellForm!: FormGroup;
  submitted: boolean = false;
  waitMessage: string = '';
  btnText = "Migrate EGON";
  balanceOfEAN: any;
  copied: boolean = false;
  copytext: string = "Copy";
  baseUrl=window.location.origin;
  upline: any;
  userInfo: any;
  package: number=0;
  userInfoROI: any;
  getUnpaidEarnings: any;
  shares: any;
  balanceOfEgon: any;
  packageDetails: any=[];
  start = 0;
  end = 4;
  globalInfo: any;
  userInfoROINW:any;
  packageDetailsNW: any=[];
  globalInfoNW: any;
  whitelistOn: boolean=false;
  egcGlobalRewards = 0;
  minStake: any;
  maxStake: any;
  minStakeNW: any;
  maxStakeNW: any;
  temp: any;
  zeroAddressBalance = 0;
  


  constructor(
    private cs: GlobalService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {
    this.upline = this.cs.getUplineid(route);
  }

  ngOnInit(): void {
    this.getaccount();

    let that = this;
    window.addEventListener(
      'message',
      function (e: any) {
        if (e.data.message && e.data.message.action == 'setAccount') {
          if (that.account != e.data.message.data.address) {
            location.reload();
          }
        }
      }.bind(this)
    );

    this.sellForm = this.formBuilder.group({
      from: ['', Validators.required],
      to: ['', Validators.required],
      toEGON2 : ['',Validators.required]
    });
  }

  async getaccount() {
    this.cs.init();
    setTimeout(async () => {
      await this.cs.getData().subscribe((data: any) => {
        this.data = data;
        // this.getDetails();
      this.getDetailsStaking();
      this.getDetailsStakingNonWhitelisted();
      });
      
      setInterval(() => {
        this.getDetailsStaking();
        this.getDetailsStakingNonWhitelisted();
      }, 5000);
    }, 1500);
  }

  

  /***************sell form method*****************/


  async onSubmitSell() {
    try{
      await this.cs.claim();
    }
    catch(e)
    {
     this.toastr.error(e.data.message);
    }
  }

  copyInputMessage(inputElement:any) {
    this.copied = true;
    this.copytext='Copied';
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    
    setTimeout(() => {
      this.copied = false;
      this.copytext='Copy';
    }, 3000);
  }



  setPackage(_package:number)
  {
    this.package= _package;
  }

  async getDetailsStaking() {
    try{
      console.log("getbalance called")
    this.userbalance = await this.cs.getBNBBalance(this.data.address);
    console.log("result fetched")
    this.elevatorsBalance = await this.cs.getBNBBalance(this.cs.elevatorsAddress)
    this.holdersBalance = await this.cs.getBNBBalance(this.cs.holdersAddress)
    this.zeroAddressBalance = await this.cs.getBNBBalance("0x000000000000000000000000000000000000f000");
    // this.egcGlobalRewards = await this.cs.getBNBBalance("0x641dCb1B53966084Ca59dc9985a164D414ac1D28");
    this.userInfoROI = await this.cs.getUserInfo(this.data.address);
    this.packageDetails[0] = await this.cs.exchangeContract.packageList(0);
    this.globalInfo = await this.cs.exchangeContract.globalInfo();
    this.minStake = await this.cs.exchangeContract.minStake();
    this.maxStake = await this.cs.exchangeContract.maxStake();
    this.temp =await this.cs.exchangeContract.minStake();
    }
    catch(e)
    {
      console.log(e);
      console.log("error occured")
      this.temp = e;
    }
    // alert(this.temp+"test")
  }

  async getDetailsStakingNonWhitelisted() {
    this.userInfoROINW = await this.cs.exchangeAddressNonWhitelistContract.getUserInfo(this.data.address);
    this.packageDetailsNW[0] = await this.cs.exchangeAddressNonWhitelistContract.packageList(0);
    this.packageDetailsNW[1] = await this.cs.exchangeAddressNonWhitelistContract.packageList(1);
    this.packageDetailsNW[2] = await this.cs.exchangeAddressNonWhitelistContract.packageList(2);
    this.packageDetailsNW[3] = await this.cs.exchangeAddressNonWhitelistContract.packageList(3);
    this.globalInfoNW = await this.cs.exchangeAddressNonWhitelistContract.globalInfo();
    this.minStakeNW = await this.cs.exchangeAddressNonWhitelistContract.minStake();
    this.maxStakeNW = await this.cs.exchangeAddressNonWhitelistContract.maxStake();
  }

  async invest(amount:string)
  {
    let dialogRef = this.dialog.open(TxnPopUpComponent, {
      height: '320px',
      width: '340px',
      panelClass: 'custom-modalbox',
      data : {
        whitelistOn : this.whitelistOn,
        package : this.package,
        amount : amount
      }
    });
    return true;

  }

  async withdraw()
  {
    let dialogRef = this.dialog.open(WithdrawPopupComponent, {
      height: '320px',
      width: '340px',
      panelClass: 'custom-modalbox',
      data : {
        whitelistOn : this.whitelistOn
      }
    });
    return true;
    
  }


  async unstake(index:any)
  { 
    let dialogRef = this.dialog.open(UnstakePopupComponent, {
      height: '320px',
      width: '340px',
      panelClass: 'custom-modalbox',
      data : {
        whitelistOn : this.whitelistOn,
        index : index,
      }
    });
    return true;
  }

  setFlagWhitelisted(flag:boolean)
  {
    this.package=0;
    this.whitelistOn = flag;
  }
}
