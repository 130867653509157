<!-- Apps Craft Main Menu -->
<header class="apps-craft-main-menu-area" id="apps-craft-menus">
  <div class="container">
    <div class="row align-items-center Header_top">
      <div _ngcontent-jlf-c81="" class="logo_container">
        <div _ngcontent-jlf-c81="" class="apps-craft-logo">
          <a
            _ngcontent-jlf-c81=""
            href="javascript:void(0)"
            [routerLink]="['/']"
            style="display: flex; align-items: center; justify-content: center"
            >
            <img  src="assets/img/logo.png" alt="" title="" class="sitebuilder-element normal-image-element" style="opacity: 1; border-radius: 0px; border: 0px none rgb(0, 0, 0); box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px; width: 50px; margin-right: 10px;">

            <h2
              class="default">
              <span
                style="
                  color: rgb(243, 156, 18);
                  -webkit-text-fill-color: rgb(243, 156, 18);
                  font-size: 18px;"></span>
                  <span
                style="color: rgb(179 91 74);-webkit-text-fill-color: rgb(179 91 74);font-size: 18px;vertical-align: super;"
                >Elevated Staking Dapp</span>
            </h2></a>
        </div>
      </div>

      <div class="Button_section">

        <nav class="apps-craft-menu-and-serach clear-both navbar navbar-expand-lg navbar-light bg-light">
          <button class="navbar-toggler menu-bar" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
            <div id="toggle"
              class="la-ball-elastic-dots la-2x item-generate"
              style="color: #ffcc33"
            >
              <div></div>
              <div></div>
              <div></div>
            </div>
          </button>
          <div id="apps-craft-menu">
          <div class="apps-craft-menu collapse navbar-collapse" *ngIf="!isConnected" id="navbarTogglerDemo01">
            <ul class="px-3">
              <li><a href="javascript:void(0);" [routerLink]="['/swap']">Stake</a></li>
              <li style="margin-right: 10px;">
                <button class="apps-craft-btn solid-color w-100" style="margin-right: 10px;" (click)="connectWallet()"> Connect Wallet </button>
              </li>
              <!-- <li>
                <button class="apps-craft-btn solid-color w-100" style="background: linear-gradient( 
                  90deg, rgb(102, 121, 229) 0%, rgb(64 8 8) 100%);"  (click)="connectWallet()"> Connect Metamask </button>
              </li> -->
              
            </ul>
          </div>

          <div class="apps-craft-menu collapse navbar-collapse d-lg-flex" *ngIf="isConnected" id="navbarTogglerDemo01">
            <li>
              <button *ngIf="isConnected" class="w-100 apps-craft-btn solid-color w-100"
             >
                {{data.address.substring(0,16)}}...
              </button>
            </li>
            <li>
              <button *ngIf="isConnected" (click)="disconnect()" class="w-100 purple-outline apps-craft-btn solid-color w-100">
                Disconnect
              </button>
            </li>
          </div>

          <!-- <div class="d-xs-block" >
                <button *ngIf="isConnected" class="w-100 apps-craft-btn solid-color w-100"
                style="    background: linear-gradient( 
                  90deg, rgb(102, 121, 229) 0%, rgb(64 8 8) 100%);">
                  {{data.address.substring(0,16)}}...
                </button>
                <button *ngIf="isConnected" (click)="disconnect()" class="w-100 apps-craft-btn solid-color w-100">
                  Disconnect
                </button>
          </div> -->
          </div>
        </nav>

        <!-- <div class="apps-craft-menu-and-serach clear-both">
          <span id="apps-craft-main-menu-icon">
            <div id="toggle"
              class="la-ball-elastic-dots la-2x item-generate"
              style="color: #fa326f"
            >
              <div></div>
              <div></div>
              <div></div>
            </div>
          </span>
          <nav id="apps-craft-menu" class="apps-craft-menu">
            <ul>
              <li><a href="javascript:void(0);" [routerLink]="['/stake']">Mining</a></li>
              <li><a href="javascript:void(0);" [routerLink]="['/stake']">Staking</a></li>
              <li><a href="javascript:void(0);" [routerLink]="['/swap']">Swap</a></li>
              <li><a href="javascript:void(0);">Affiliate</a></li>
              <li>
				<button *ngIf="!isConnected" class="apps-craft-btn solid-color" (click)="connectWallet()"  data-v-f8bced16="">
					Connect
				</button>
				<button *ngIf="isConnected" class="apps-craft-btn solid-color w-100">
					{{data.address.substring(0,6)}}...
				</button>
              </li>
            </ul>
          </nav>
        </div> -->
        <!-- .apps-craft-menu-and-serach END -->
      </div>
    </div>
  
</div>
</header>
<!-- .apps-craft-main-menu END -->

<!-- 
<header class="bg-dark" data-v-f8bced16="" data-v-4ee7c6ca="">
    <nav class="navbar navbar-expand-lg navbar-dark p-0 mx-auto" data-v-f8bced16="">
        <div class="container-fluid" data-v-f8bced16="">
            <a href="/" class="navbar-logo d-flex align-items-center animate__animated nuxt-link-exact-active nuxt-link-active"  data-v-f8bced16="" aria-current="page">
                <img src="../../../assets/images/img2/logo.jpeg" alt="logo" class="navbar-brand p-0" data-v-f8bced16="">
                    <span class="text-primary fw-bold navbar-brand-text"  data-v-f8bced16="">
                     CNN Coin
                    </span>
            </a>
            <button *ngIf="!isConnected" class="btn btn-primary btn-connect" (click)="connectWallet()"  data-v-f8bced16="">
                Connect
            </button>
            <button *ngIf="isConnected" class="btn btn-primary btn-connect">
                {{data.address.substring(0,6)}}...
            </button>

            
          


        </div>
    </nav>
</header>

 -->
