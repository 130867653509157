import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ethers } from 'ethers';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-unstake-popup',
  templateUrl: './unstake-popup.component.html',
  styleUrls: ['./unstake-popup.component.css']
})
export class UnstakePopupComponent implements OnInit {
  modalOpened: boolean = false;
  btnText: string="";

  constructor(public dialogRef: MatDialogRef<UnstakePopupComponent>,private cs:GlobalService,@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.getDetails();
  }

   async getDetails()
  {
    try {
        this.btnText="Transaction Initiated...";
        var status: any;
        
        if(this.data.whitelistOn){
          status = await this.cs.exchangeContract.unstake(this.data.index);
          }
          else{
            status = await this.cs.exchangeAddressNonWhitelistContract.unstake(this.data.index);
          }
          this.btnText = "Waiting for Confirmation...";
          await status.wait(3);
          this.btnText = "Transaction Completed Successfully....!";
    }catch (e) {
      this.btnText = "Something went wrong";
      
      // if (e.code == 4001){
      //    this.toastr.error(e.message);
      // }
      // else{
      //    this.toastr.error(e.data.message);
      // }
    }
  }

  closeDialog() {
    this.dialogRef.close('Pizza!');
  }

}
