import { Injectable } from '@angular/core';
import {  Observable, BehaviorSubject } from 'rxjs';
import { WindowRefService } from './window-ref.service';
import { ethers } from "ethers";
import { ToastrService } from 'ngx-toastr';
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ActivatedRoute } from '@angular/router';

export const eanAddress = "0xf711D680DD3e987B6bd24253931E5495C954b557";
export const egonAddress = "0x05995a068bdac17c582eC75AB46bb8e7394be1d9";
export const ExchangeAddress = "0xaEbC3307309D3475309B872Fd869eD3240BdC4Aa";
export const ExchangeAddressNonWhitelist = "0xCf4C502a2Be5E83Ae138fC88E4b69ef93795E990";

const aklmTokenAbi = require('./../../assets/abis/aklm.json');
const exchangeAbi = require('./../../assets/abis/exchange.json');
const dividendAbi = require('./../../assets/abis/dividend.json');
//build command 
const providerOptions = {
  rpc: {
    271: "https://rpc.egonscan.com",
 },
 network: "EGC",
 chainId: 271, 
};
//  Create WalletConnect Provider
const provider = new WalletConnectProvider(providerOptions);


@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  url_id: any;

  private toggle = new BehaviorSubject<any>({});
  public data = new BehaviorSubject<any>({});
  public tokenomicsData: any;
  public oldPancakeAddress = true;
  provider!: any;
  signer!: ethers.providers.JsonRpcSigner;
  eanContract: any;
  exchangeContract: any;
  egonContract:any;
  ownerAddress = "0x5f66dc2354b6498ff6efd6cfc98f92fe3d380d43";
  exchangeAddressNonWhitelistContract!: ethers.Contract;
  elevatorsAddress = ExchangeAddress;
  holdersAddress = ExchangeAddressNonWhitelist;
  
  constructor(private windowRef: WindowRefService,private toastr: ToastrService
  ) {

  }

  onToggle(state?: boolean) {
    this.toggle.next(state);
  }

  whenToggled(): Observable<any> {
    return this.toggle.asObservable();
  }

  updateData(state: any) {
    this.tokenomicsData = state;
    this.data.next(state);

  }

  getData(): Observable<any> {

    return this.data.asObservable();
  }

  init(): void {
    console.log(localStorage.getItem('wallet'));
    if(localStorage.getItem('wallet')=="2"){
    this.connectToWalletConnect();
    }
    else if(localStorage.getItem('wallet')=="1"){
      this.connectToWallet();
    }
    

  }

  async connectToWallet() {

    if (typeof this.windowRef.nativeWindow.ethereum !== undefined) {
      await this.windowRef.nativeWindow.ethereum.enable();
      this.provider = new ethers.providers.Web3Provider(this.windowRef.nativeWindow.ethereum)
      await this.getAccountAddress();
      localStorage.setItem('wallet', '1');
      // Subscribe to accounts change
      this.windowRef.nativeWindow.ethereum.on("accountsChanged", (accounts: string[]) => {
        this.connectToWallet();
        location.reload();
      });


      // Subscribe to session disconnection
      this.windowRef.nativeWindow.ethereum.on("networkChanged", (code: number, reason: string) => {
        this.connectToWallet();
        location.reload();
      });

    }

  }

  async connectToWalletConnect() {
//  Enable session (triggers QR Code modal)
console.log("wallet connect");
await provider.enable();
this.provider = new ethers.providers.Web3Provider(provider);

 //     this.provider = new ethers.providers.Web3Provider(this.windowRef.nativeWindow.ethereum)
      await this.getAccountAddress();
      localStorage.setItem('wallet', '2');
      // Subscribe to accounts change
      provider.on("accountsChanged", (accounts: string[]) => {
        this.connectToWallet();
        location.reload();
      });


      // Subscribe to session disconnection
      provider.on("networkChanged", (code: number, reason: string) => {
        this.connectToWallet();
        location.reload();
      });

  }

  async disconnect()
  {
    await provider.disconnect();
  }


  async getAccountAddress() {
    this.signer = this.provider.getSigner();
    var address = await this.signer.getAddress();

    var network = await this.provider.getNetwork();

    localStorage.setItem('address', address);
    debugger
    if (network.chainId == 271271) {
      this.eanContract = new ethers.Contract(eanAddress, aklmTokenAbi, this.signer);
      this.exchangeContract = new ethers.Contract(ExchangeAddress, exchangeAbi, this.signer);
      this.exchangeAddressNonWhitelistContract = new ethers.Contract(ExchangeAddressNonWhitelist,dividendAbi,this.signer);
      this.egonContract = new ethers.Contract(egonAddress,aklmTokenAbi,this.signer);
    }
    var data = {
      'provider': this.provider,
      'signer': this.signer,
      'aklmContract': this.eanContract,
      'exchangeContract': this.exchangeContract,
      'address': address,
      'networkId': network,
      'aklmAddress': eanAddress
    }

    this.updateData(data);
  }


  

  async getBalanceOfUserAKLM(userAddress: any) {
    var promise = new Promise((resolve, reject) => {
      this.eanContract.balanceOf(userAddress).then(function (params: any) {
        resolve(params)
      })
    });
    return promise;
  }

  async getBalanceOfEgon(userAddress:any)
  {
    return await this.egonContract.balanceOf(userAddress);
  }

  async getBNBBalance(address:any){
    return await this.provider.getBalance(address);
  }



  async getpriceInUsdt() {
    var promise = new Promise((resolve, reject) => {
      this.exchangeContract.priceInUsdt().then(function (params: any) {
        resolve(params)
      })
    });
    return promise;
  }



  async totalWithdrawn() {
    var promise = new Promise((resolve, reject) => {
      this.exchangeContract.totalWithdrawn().then(function (params: any) {
        resolve(params)
      })
    });
    return promise;
  }


  async totalInvested() {
    var promise = new Promise((resolve, reject) => {
      this.exchangeContract.totalInvested().then(function (params: any) {
        resolve(params)
      })
    });
    return promise;
  }


  async convertBNBtoUsdValue(amount: any) {
    let amt = ethers.utils.parseEther(amount.toString());
    var promise = await this.exchangeContract.convertBNBtoUsdValue(amt);
    return promise;
  }

  async convertUsdToBNB(amount: any) {
    let amt = ethers.utils.parseEther(amount.toString());
    var promise = await this.exchangeContract.convertUsdToBNB(amt);
    return promise;
  }

  getUplineid(route: ActivatedRoute) {
    let found = false;
    route.params.subscribe(
      (params) => {
        if (params['ref'] !== undefined) {
          this.url_id = params['ref'];
          found = true;
        }
      }
    );



    if (localStorage.getItem("upline") != null && localStorage.getItem("upline") !== "undefined" && !found) {
      this.url_id = localStorage.getItem("upline");
    }
    else if (found) {
      localStorage.setItem("upline", this.url_id);
    }
    else if (!found && (localStorage.getItem("upline") === null || localStorage.getItem("upline") === "undefined")) {
      this.url_id = this.ownerAddress;
    }

    return this.url_id;

  }

  async   claim() {
      var promise = await this.exchangeContract.claimDividend()
      return { status: true, txn: promise };
    
  }

  async   userInfo(userAddress:any) {
    return await this.exchangeContract.users(userAddress);
  }

  async getUserInfo(userAddress:any)
  {
    return await this.exchangeContract.getUserInfo(userAddress);

  }



  async getEvent(address:any)
  {
    let eventFilter = this.exchangeContract.filters.RefBonus(address,null,1)
  let events = await this.exchangeContract.queryFilter(eventFilter)
  return events;
  }

  async allowToken(fromAmount: any) {
    
    var promise = new Promise((resolve, reject) => {
      let price = ethers.utils.parseEther(fromAmount);
      let tx = this.eanContract.approve(ExchangeAddress, price);
      resolve(tx)
    });
    return promise;
  }

  async checkAllowance(fromAmount: any,userAddress:any) {
   
    var promise = new Promise((resolve, reject) => {
      let price = ethers.utils.parseEther(fromAmount);

      this.eanContract.allowance(userAddress, ExchangeAddress).then(function (params: any) {
        resolve({ allowedAmount: params, mainAmount: price })
      })
    });
    return promise;
  }

}
