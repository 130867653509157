<section
  class="apps-craft-welcome-section section-padding"
  id="apps-craft-faq">
  <div class="container">

    <div class="apps-craft-section-heading">
      <h2>Refer Your Friends</h2>
      <div class="panel-default">
        <div class="">
          <h4 class="text-white">
            Invite your friends to CNN COIN staking program and enjoy 10% on their staking reward
          </h4>
          <a href="javascript:void(0)" (click)="copyInputMessage(reflink)" class="apps-craft-btn solid-color"> {{copytext === 'Copy' ? 'Get Your Referral Link' : 'Copied'}}</a>
        </div>
      </div>
    </div>

     <!-- .apps-craft-section-heading END -->
    <div class="row content-margin-top">
      <div class="col-md-3 col-sm-3 col-xs-12">
        <img
        src="assets/img/home/banner.png"
        class="wow fadeInRight"
        data-wow-delay=".4s"
        alt="CNN Coin Why Chose Image Screenshort"
        />
        </div>
      <div class="col-md-5 col-sm-5 col-xs-12">

        <div
          class="wow fadeIn"
          data-wow-delay="800ms"
          style="
            visibility: visible;
            animation-delay: 800ms;
            animation-name: fadeIn;
          "
        >
          <div class="apps-craft-contact-form-content-inner">
            <div class="apps-craft-contact-form" style="color: white">
              <form action="#" method="POST" id="apps-craft-form">

                <div
                  class="">
                  <div class="card-header bg-white border-0 pb-0">
                    <h2 class="card-title mb-0">Affiliate Reward on STAKING</h2>
                  </div>
                  <div class="table-responsive mt-5">
                    <table class="table table-striped">
                      <tbody>
                      <tr>
                        <td>
                          LEVEL 1  
                        </td>
                        <td>
                            10%
                        </td>
                      </tr>
                      <tr>
                        <td>
                          LEVEL 2 
                        </td>
                        <td>5%</td>
                      </tr>
                      <tr>
                        <td>
                          LEVEL 3
                        </td>
                        <td>
                            3%
                        </td>
                      </tr>
                      <tr>
                        <td>
                           LEVEL 4
                        </td>
                        <td>
                            2%
                        </td>
                      </tr>
                      <tr>
                        <td>
                          LEVEL 5
                        </td>
                        <td>
                            1%
                        </td>
                      </tr>
                    </tbody>
                    </table>
                  </div>
                </div>
              </form>
            </div>
            <!-- .apps-craft-contact-form END -->
          </div>
          <!-- .apps-craft-contact-form-content-inner END -->
        </div>

       
      </div>

      <div class="table-responsive mt-5">
        Direct Referral Address
        <table class="table table-striped">
          <tbody>
          <tr *ngFor="let item of withdrawable">
            <td>
              {{item.args.referral}}
            </td>
          
          </tr>
        
        </tbody>
        </table>
      </div>


      <div class="col-md-4 col-sm-4 col-xs-12">
        <div
        class="apps-craft-accordion wow fadeIn"
        data-wow-delay="400ms"
        style="
          visibility: visible;
          animation-delay: 400ms;
          animation-name: fadeIn;
        "
      >
      </div>
      <div class="box-green bg-secondary rounded p-3 mb-3">
        <p >Your personal link:</p>
        <div ><input 
                type="text" #reflink class="tron-link position-absolute" value="{{baseUrl}}/stake/{{data?.address}}" style="opacity: 0;position: absolute;">
            <div  class="d-flex w-100">
                <div  class="mb-0 w-100 p-2 ps-3 bg-white rounded me-n11"><small
                         class="py-1 mt-1 copy-link d-block text-truncate">
                         {{baseUrl}}/stake/{{data?.address}}
                    </small></div>
                <div  class="tooltip-container" (click)="copyInputMessage(reflink)"><button 
                        class="apps-craft-btn solid-color mt-4 mb-4"><span  class="py-1 d-block">
                            {{copytext}} </span></button> <span 
                        class="tooltip tron-tooltip">{{copytext}}</span></div>
            </div>
        </div>
    </div>
        <!-- .apps-craft-contact-form-content END -->
      </div>
    
    </div>
  </div>
</section>
