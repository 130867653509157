import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConnectwalletComponent } from 'src/app/connectwallet/connectwallet.component';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  data: any;
  isConnected: boolean = false;

  constructor(private cs:GlobalService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getaccount();
  }

  connectWallet()
  {
    // if(walletType==1)
    // this.cs.connectToWallet();
    // else
    // this.cs.connectToWalletConnect();
    let dialogRef = this.dialog.open(ConnectwalletComponent, {
      height: '320px',
      width: '340px',
      panelClass: 'custom-modalbox'
    });

  }

  async getaccount() {
    this.cs.init();
    setTimeout(async() => {
      await this.cs.getData().subscribe((data:any)=>{
        if(this.data!=data && data.address!=undefined){
          this.data=data;
          this.isConnected = true;
        }
        
      });
    },1000);
  }

  async disconnect()
  {
    localStorage.setItem('wallet', '0');
    
    await this.cs.disconnect();
    location.reload();
  }
  
}
