import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {  MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { SwapComponent } from './swap/swap.component';
import { ToastrModule } from 'ngx-toastr';
import { AffiliateComponent } from './affiliate/affiliate.component';
import { Select2Module } from 'ng-select2-component';
import { ConnectwalletComponent } from './connectwallet/connectwallet.component';
import { TxnPopUpComponent } from './swap/txn-pop-up/txn-pop-up.component';
import { WithdrawPopupComponent } from './swap/withdraw-popup/withdraw-popup.component';
import { UnstakePopupComponent } from './swap/unstake-popup/unstake-popup.component';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SwapComponent,
    AffiliateComponent,
    ConnectwalletComponent,
    TxnPopUpComponent,
    WithdrawPopupComponent,
    UnstakePopupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    ReactiveFormsModule,
    Select2Module,
    ToastrModule.forRoot(), // ToastrModule added
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
