<app-header></app-header>

<router-outlet></router-outlet>


<section>
  <div class="footer">
    <p class="mb-0 text-black">EgonCoin Elevated Staking Dapp 2022 © All Rights Reserved</p>
  </div>
</section>
