import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AffiliateComponent } from './affiliate/affiliate.component';
import { SwapComponent } from './swap/swap.component';


const routes: Routes = [
  {path: '' , component: SwapComponent},
  {path: 'ref/:ref' , component: SwapComponent},
 

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
